<template>
    <div>
        asd asdds
		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1150 !important;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

        props: {
			slug: {
				type: String,
				required: true
			}
		},

		components: {
			
		},

		data () {
			return {
                dateFormat: 'YYYY-MM-DD',
                utils,
				moment,
				locale,
				imagenCargada: false,
				spinnerloading: false
			}
		},

		computed: {
			
		},

		methods: {
			
		},

        mounted () {
			
		},

		beforeDestroy () {
			
		}
	}
</script>